<template>
  <div class="team-container" v-bind:style="rowDireciton">
    <div class="flag-container">
      <img :src="flagURL" :alt="`${props.team} flag`" />
    </div>
    <div class="team-name-container">
      <span class="team-name">{{ props.team }}</span>
    </div>  
  </div>
</template>

<style scoped>
.team-container {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}
.flag-container {
  width: 53px;
  height: 37px;
}
.flag-container img {
  width: 100%;
  height: 100%;
  object-fit: fill;
}
.reverse {
  flex-direction: row-reverse;
}
</style>

<script setup>
import { computed, defineProps } from 'vue';

const props = defineProps({
  team: {
    type: String,
    required: true
  },
  side: {
    type: String,
    required: true
  }
})

const flagURL = computed(() => {
  return `https://flagsapi.codeaid.io/${props.team}.png`;
})

const rowDireciton = computed(() => {
  return {
    "flex-direction": props.side === 'right' ? 'row-reverse' : 'row'
  }
})
</script>