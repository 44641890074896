<template>
  <div class="leaderboard-page">
    <!-- Heading -->
    <div class="heading">
      League Standings
    </div>
    <leaderboard-table class="leaderboard-table" :leaderboard="leaderboard" />
  </div>
</template>

<style scoped>
.leaderboard-page {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;

  padding: 60px 5% 0;
}
.leaderboard-table {
  width: 100%;
}
</style>

<script setup>
import { ref, onMounted } from 'vue';

import LeaderboardTable from '../components/Leaderboard/LeaderboardTable.vue';

import LeagueService from '../services/LeagueService';

const leaderboard = ref([])
const leagueService = new LeagueService();
const getLeaderboard = async () => {
  await leagueService.fetchData();
  leaderboard.value = leagueService.getLeaderboard();
}

onMounted(() => {
  getLeaderboard();
})
</script>