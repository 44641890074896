import api, { isAuthenticated, fetchAccessToken, setAccessToken } from './api'

/**
 * A class representing a service that processes the data for match schedule
 * and generates leaderboard.
 * 
 * NOTE: MAKE SURE TO IMPLEMENT ALL EXISITNG METHODS BELOW WITHOUT CHANGING THE INTERFACE OF THEM, 
 *       AND PLEASE DO NOT RENAME, MOVE OR DELETE THIS FILE.  
 * 
 *       ADDITIONALLY, MAKE SURE THAT ALL LIBRARIES USED IN THIS FILE FILE ARE COMPATIBLE WITH PURE JAVASCRIPT
 * 
 */
class LeagueService {
    #matches = [];
    
    /**
     * Sets the match schedule.
     * Match schedule will be given in the following form:
     * [
     *      {
     *          matchDate: [TIMESTAMP],
     *          stadium: [STRING],
     *          homeTeam: [STRING],
     *          awayTeam: [STRING],
     *          matchPlayed: [BOOLEAN],
     *          homeTeamScore: [INTEGER],
     *          awayTeamScore: [INTEGER]
     *      },
     *      {
     *          matchDate: [TIMESTAMP],
     *          stadium: [STRING],
     *          homeTeam: [STRING],
     *          awayTeam: [STRING],
     *          matchPlayed: [BOOLEAN],
     *          homeTeamScore: [INTEGER],
     *          awayTeamScore: [INTEGER]
     *      }    
     * ]
     * 
     * @param {Array} matches List of matches.
     */    
    setMatches(matches) {
        if (!Array.isArray(matches)) throw new Error('matches must be an array');
        this.#matches = matches;
    }

    /**
     * Returns the full list of matches.
     * 
     * @returns {Array} List of matches.
     */
    getMatches() {
        return this.#matches;
    }

    /**
     * Returns the leaderboard in a form of a list of JSON objecs.
     * 
     * [     
     *      {
     *          teamName: [STRING]',
     *          matchesPlayed: [INTEGER],
     *          goalsFor: [INTEGER],
     *          goalsAgainst: [INTEGER],
     *          points: [INTEGER]     
     *      },      
     * ]       
     * 
     * @returns {Array} List of teams representing the leaderboard.
     */
    getLeaderboard() {
        const teams = {};

        this.#matches.forEach(match => {
            // Make sure the team exists in the teams object
            if (!teams[match.homeTeam]) {
                teams[match.homeTeam] = {
                    teamName: match.homeTeam,
                    matchesPlayed: 0,
                    goalsFor: 0,
                    goalsAgainst: 0,
                    points: 0
                }
            }

            if (!teams[match.awayTeam]) {
                teams[match.awayTeam] = {
                    teamName: match.awayTeam,
                    matchesPlayed: 0,
                    goalsFor: 0,
                    goalsAgainst: 0,
                    points: 0
                }
            }

            // Tally matches played
            teams[match.homeTeam].matchesPlayed += match.matchPlayed ? 1 : 0;
            teams[match.awayTeam].matchesPlayed += match.matchPlayed ? 1 : 0;

            // Tally goals for and against
            teams[match.homeTeam].goalsFor += match.homeTeamScore;
            teams[match.homeTeam].goalsAgainst += match.awayTeamScore;

            teams[match.awayTeam].goalsFor += match.awayTeamScore;
            teams[match.awayTeam].goalsAgainst += match.homeTeamScore;

            // Tally points
            if (match.matchPlayed) {
                if (match.homeTeamScore > match.awayTeamScore) {
                    teams[match.homeTeam].points += 3;
                } else if (match.homeTeamScore < match.awayTeamScore) {
                    teams[match.awayTeam].points += 3;
                } else {
                    teams[match.homeTeam].points += 1;
                    teams[match.awayTeam].points += 1;
                }
            }
        });

        // Sort teams by points, goal difference, goals for, then alphabetically
        return Object.values(teams).sort((a, b) => {
            if (a.points > b.points) return -1;
            if (a.points < b.points) return 1;

            if (a.goalsFor - a.goalsAgainst > b.goalsFor - b.goalsAgainst) return -1;
            if (a.goalsFor - a.goalsAgainst < b.goalsFor - b.goalsAgainst) return 1;

            if (a.goalsFor > b.goalsFor) return -1;
            if (a.goalsFor < b.goalsFor) return 1;

            if (a.teamName < b.teamName) return -1;
            if (a.teamName > b.teamName) return 1;

            return 0;
        });
    }
    
    /**
     * Asynchronic function to fetch the data from the server.
     */
    async fetchData() {
        try {
            // Check if we already have an access token, if not, fetch one
            if (!isAuthenticated()) {
                const accessToken = await fetchAccessToken();
                setAccessToken(accessToken);
            }

            // Fetch the matches data
            const { data } = await api.get('/v1/getAllMatches')
            if (!data.success) throw new Error('Could not fetch matches')

            this.setMatches(data.matches);
        } catch (err) {
            console.error(err)
        }
    }
}

export default LeagueService;